<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveFaQ" @reset="onReset">

              <div class="col-md-12">
                <b-form-group
                    id="input-group-1"
                    label="Câu hỏi(*):"
                    label-for="input-2"

                >
                  <b-form-input required v-model="form.aks" ></b-form-input>

                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                    id="input-group-1"
                    label="Câu trả lời(*):"
                    label-for="input-2"
                    required
                >
                  <vue-editor  required v-model="form.answer"></vue-editor>

                </b-form-group>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <b-form-group
                      id="input-group-1"
                      label="Vị trí:"
                      label-for="input-1"
                      class="col-md-6"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="form.position"
                        required
                        min="0"
                        type="number"
                    />
                  </b-form-group>
                  <div class="form-inline col-md-6">
                    <input  id="show-bn-cms" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                    <label for="show-bn-cms"  style=" margin-left: 10px">Hiển thị </label>
                  </div>
                </div>
              </div>



              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
                <router-link v-if="is_edit" :to="'/campaign/rule'">
                  <b-button variant="danger">Hủy bỏ</b-button>
                </router-link>
                <b-button v-else type="reset" variant="danger">Làm mới</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import { VueEditor } from "vue2-editor";
const CampaignRepository = RepositoryFactory.get("Campaign");

export default {
  name: "FormCategory",
  mixins: [Common],
  components: {
    VueEditor
  },
  data() {
    return {
      form: {
        id: '',
        status: 1,
        answer: '',
        aks: '',

      },
      configs:[
        {
          label: '',
          value: '',
        }
      ],
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách FaQ ", route: "/campaign/faq" },
      { title: this.is_edit ?"Sửa FaQ" : "Thêm FaQ" },
    ]);
  },
  methods: {
    async loadFaQ(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getFaq(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.form.status = body.data.status;
          this.form.answer = body.data.answer;
          this.form.position = body.data.position;
          this.form.aks = body.data.aks;
          if (body.data.extra_data){
            this.configs = JSON.parse(JSON.parse(body.data.extra_data));
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveFaQ: async function () {

      this.form.extra_data = JSON.stringify(this.configs);
      let params = this.form;
      if (this.is_edit) {
        this.update(params);
        return false;
      }

      CampaignRepository.createFaq(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_faq_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    update: async function (params) {
      this.$bus.$emit("show-loading", true);

      CampaignRepository.updateFaq(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_faq_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        name: "",
        status: 1,
        icon: '',
      }

    },
  },
  created() {
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadFaQ(this.$route.params.id);
    }
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
